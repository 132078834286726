@import 'node_modules/normalize.css/normalize.css';
@import 'style-utils';

:root {
  min-width: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  scrollbar-gutter: stable;
  height: 100%;
}

html {
  scroll-behavior: smooth;
  font-family: $roboto;
  overflow: initial !important;
}

body {
  overflow-x: hidden;
  width: 100%;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

button {
  cursor: pointer;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-user-select: none;
  user-select: none;

  background: none;
  border: none;
  outline: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

svg-icon {
  display: inline-block;
}

.magenta {
  color: $magenta;
}

.pink {
  color: $pink;
}

.blue {
  color: $blue;
}

.blue2 {
  color: $blue2;
}

.darkblue {
  color: $darkblue;
}

.dark {
  color: $dark;
}

.green {
  color: $green;
}

.container {
  position: relative;
  z-index: 1;
  padding: 0 15px;

  @include media(ipadBig) {
    width: 900px;
    margin: 0 auto;
    padding: 0.1px 0;
  }

  @include media(desktop) {
    width: 1360px;
    margin: 0 auto;
    padding: 0.1px 0;
  }
}

.button {
  text-align: center;
  @include roboto-bold;
  position: relative;
  z-index: 2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 303px;
  max-width: 100%;
  min-height: 58px;
  margin: 0 auto;

  font-size: 20px;
  line-height: 1.2;
  color: $white;
  letter-spacing: 0.3px;

  background: $magenta;
  border: 1px solid #93b7f1;
  border-radius: 16px;

  @include roboto-bold;

  @include media(desktop) {
    width: 330px;
    min-height: 76px;
    font-size: 22px;
    border-radius: 18px;
  }

  &-gradient {
    background: linear-gradient(90deg, $pink -5%, $blue2 105%);

    @include media(desktop) {
      width: 365px;
      min-height: 76px;
      font-size: 26px;
      border-radius: 22px;
    }
  }

  &:hover {
    background: $blue2;
    color: white;
  }
  &-pink {
    background: $pink;
  }

  &-blue {
    background: $blue;
  }
  &-blue2 {
    background: $blue2;
    &:hover {
      background: $magenta;
    }
  }
  &-white {
    background: white;
    color: $dark;
  }
  &-transparent {
    background: transparent;
  }
}

body h1 {
  margin: 0 auto;
  font-size: 20px;
  line-height: 1.4;
  text-transform: uppercase;

  @include drunk;

  &.title-page {
    font-size: 24px;
    margin-bottom: 30px;
    @include media(ipad) {
      font-size: 36px;
      margin-top: 30px;
      margin-bottom: 50px;
    }
    @include media(desktop) {
      font-size: 56px;
      margin-top: 100px;
      margin-bottom: 80px;
    }
  }
  @include media(ipadBig) {
    font-size: 32px;
  }

  @include media(desktop) {
    font-size: 42px;
  }

  & span {
    white-space: nowrap;
  }
}

body h2 {
  margin: 0 auto;
  margin-bottom: 24px;

  // NOTE: временный important, чтобы перебить внешние стили из виджета
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;

  @include drunk;

  @include media(ipadBig) {
    margin: 0;
    font-size: 28px;
    text-align: left;
  }

  @include media(desktop) {
    font-size: 35px;
  }
}

body h3 {
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.2;

  @include roboto-medium;

  @include media(ipadBig) {
    margin: 0;
    font-size: 21px;
    text-align: left;
  }

  @include media(desktop) {
    font-size: 31px;
    text-transform: uppercase;
  }
}

body h4 {
  font-size: 16px;
  line-height: 1.3;

  @include roboto-medium;

  @include media(ipad) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 42px;
  }

  @include media(desktop) {
    font-size: 24px;
  }
}

.desktopBlock {
  @media (width <= 1023px) {
    display: none !important;
  }
}

.mobileBlock {
  @include media(ipadBig) {
    display: none !important;
  }
}

.pinkBack {
  color: white;
  background: $pink;
  padding: 1px 4px;
  display: inline-block;
}

.blueBack {
  color: white;
  background: $blue2;
  padding: 1px 4px;
  display: inline-block;
}

.lightPinkBack {
  color: $black;
  background: #ffe6f8;
  padding: 1px 4px;
  display: inline-block;
}
/*
.swiper-slide {
  width: auto !important;
}
*/

.slider {
  display: flex;
  align-items: stretch;
  flex-direction: row;

  &__slide {
    width: calc(100vw - 30px);

    /* stylelint-disable-next-line order/order */
    @media (width >= 550px) {
      width: calc(50vw - 20px);
    }

    @include media(ipad) {
      width: calc(33.3vw - 20px);
    }

    @include media(ipadBig) {
      $app-width: 900px;
      width: calc($app-width / 3 - 16px);
    }

    @include media(desktop) {
      $app-width: 1360px;
      width: calc($app-width / 3 - 32px);
    }
    &-photo {
      @include media(desktop) {
        position: relative;
        $app-width: 1360px;
        width: calc($app-width / 5 - 32px);
        height: 200px;
        &:nth-child(5n + 1) {
          width: calc($app-width * 3 / 5 - 32px);
          padding-right: calc($app-width * 2 / 5);
        }
        &:nth-child(5n + 2) {
          top: 272px;
          margin-left: calc($app-width * 3 / 5 * -1);
          width: calc($app-width * 3 / 5 - 32px);
          padding-right: calc($app-width / 5);
          position: relative;
        }
        &:nth-child(5n + 3) {
          top: 0;
          width: calc($app-width / 2.5 - 32px);
          margin-left: calc($app-width * 2 / 5 * -1);
        }
        &:nth-child(5n + 4) {
          top: 272px;
          width: calc($app-width / 5 - 32px);
          margin-left: calc($app-width / 5 * -1);
        }
        &:nth-child(5n + 5) {
          top: 0;
          height: 504px;
          width: calc($app-width / 2.5 - 32px);
          & img {
            height: 100%;
          }
        }
      }
    }
    &-partner {
      width: auto;
    }
  }
}

.cdk-overlay-pane {
  overflow-y: scroll;

  scrollbar-width: none;
  overflow-y: scroll;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
