input.ng-invalid.ng-touched {
  border-color: $red !important;

  &::placeholder {
    color: $red;
  }
}

input {
  transition: border-color 0.3s ease-in;

  &::placeholder {
    transition: color 0.3s ease-in;
  }
}

input[type='checkbox'].ng-invalid.ng-touched {
  outline: 1px solid $red;
}

dp-date-picker.ng-touched.ng-invalid input,
select.ng-invalid.ng-touched {
  border: 1px solid red !important;
}

input[type='tel'].ng-invalid.ng-touched {
  color: red;
}

textarea.ng-invalid.ng-touched {
  &::placeholder {
    color: red;
  }
}
