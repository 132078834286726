@mixin roboto-black {
  font-family: $roboto;
  font-weight: 900;
  font-style: normal;
}

@mixin roboto-light {
  font-family: $roboto;
  font-weight: 300;
  font-style: normal;
}

@mixin roboto-bold-italic {
  font-family: $roboto;
  font-weight: bold;
  font-style: italic;
}

@mixin roboto-italic {
  font-family: $roboto;
  font-weight: normal;
  font-style: italic;
}

@mixin roboto-black-italic {
  font-family: $roboto;
  font-weight: 900;
  font-style: italic;
}

@mixin roboto-bold {
  font-family: $roboto;
  font-weight: bold;
  font-style: normal;
}

@mixin roboto-light-italic {
  font-family: $roboto;
  font-weight: 300;
  font-style: italic;
}

@mixin roboto-thin {
  font-family: $roboto;
  font-weight: 100;
  font-style: normal;
}

@mixin roboto-medium {
  font-family: $roboto;
  font-weight: 500;
  font-style: normal;
}

@mixin roboto-medium-italic {
  font-family: $roboto;
  font-weight: 500;
  font-style: italic;
}

@mixin roboto-regular {
  font-family: $roboto;
  font-weight: normal;
  font-style: normal;
}

@mixin roboto-thin-italic {
  font-family: $roboto;
  font-weight: 100;
  font-style: italic;
}

@mixin drunk {
  font-family: Drunk, $roboto;
  font-weight: 700;
  font-style: normal;
}
