@font-face {
  font-family: Roboto;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src:
    local('Roboto Black'),
    local('Roboto-Black'),
    url('/assets/fonts/Roboto/Roboto-Black.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    url('/assets/fonts/Roboto/Roboto-Light.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src:
    local('Roboto Bold Italic'),
    local('Roboto-BoldItalic'),
    url('/assets/fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src:
    local('Roboto Italic'),
    local('Roboto-Italic'),
    url('/assets/fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src:
    local('Roboto Black Italic'),
    local('Roboto-BlackItalic'),
    url('/assets/fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url('/assets/fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src:
    local('Roboto Light Italic'),
    local('Roboto-LightItalic'),
    url('/assets/fonts/Roboto/Roboto-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src:
    local('Roboto Thin'),
    local('Roboto-Thin'),
    url('/assets/fonts/Roboto/Roboto-Thin.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url('/assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src:
    local('Roboto Medium Italic'),
    local('Roboto-MediumItalic'),
    url('/assets/fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url('/assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-style: italic;
  font-display: swap;
  src:
    local('Roboto Thin Italic'),
    local('Roboto-ThinItalic'),
    url('/assets/fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2'),
    url('/assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: Drunk;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src:
    local('Drunk Bold'),
    local('Drunk-Bold'),
    url('/assets/fonts/Drunk/druktextwidecyr-bold.woff2') format('woff2'),
    url('/assets/fonts/Drunk/druktextwidecyr-bold.ttf') format('truetype');
}
