$black: #2F2F2F;
$dark: #152346;
$white: #fff;
$magenta: #703bf7;
$pink: #e707a1;
$lightpink: #FFE6F8;
$blue: #09c5f6;
$blue2: #2c4fda;
$blue3: #2980D6;
$darkblue: #1f4378;
$lightgray: #f4f4f4;
$darkgray: #B1B1B1;
$gray: #ccced2;
$green: #119ab2;
$red: red;
